

.admin-section {
  background-color: #fff;
  width: 80vw;
  margin: auto;
  position: absolute;
  left: 20vw;
  padding: 1.5rem;
  height: 100vh;
  overflow-y: auto;
  @media only screen and (max-width: 786px) {
    width: 100vw;
    left: 0%;
  }
}
.upload-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    max-width: fit-content;
  }
  
  .parent-container {
    width: 70vw;
    margin: auto;
    padding-top: 1.5rem;
    background: #ffffff;
    @media only screen and (max-width: 786px) {
      width: 90vw;
     }
  }
  .file-upload-section { 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    border: 3px dashed #ccc;
    position: relative;
    cursor: pointer;
    padding: 0 1.5rem;
    @media only screen and (max-width: 786px) {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 10px;
    }
  }
  .file-upload-section p {
    font-size: 0.67rem;
    font-weight: 500;
    margin-top: 8px;
    color:#ccc;
  }
  .file-upload-section input {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }
  .submit-btn {
    float: right;
    margin: auto;
    background-color: #1B154C;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 12px;
    max-width: fit-content;
    cursor: pointer;
  }
.browse-files {
    background-color: #fff;
    color:#1B154C;
    font-size: 12px;
    border-radius: 10px;
    border: 2px solid #1B154C;
    padding: 10px 20px;
    font-weight: 900;
    max-width: fit-content;
    cursor: pointer;
}
.loading-icon {
  position: absolute;
  left: 50%;
  top: 30%;
}
.admin-chat-btn {
  background-color: #1B154C;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 12px;
  width: max-content;
  cursor: pointer;
  margin-right: 20px;
  @media only screen and (max-width: 786px) {
    margin-bottom: 10px;
  }
}
.create-btn {
  background-color: #1B154C;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 12px;
  width: max-content;
  cursor: pointer;
}
.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 70px;
}
.submit-btn:disabled {
  float: right;
  background-color: #ccc;
  border: 1px solid #d3d3d3;
  color: #000;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 12px;
  max-width: fit-content;
}
.table-container-wrapper {
  margin-top: 20px;
}
.parent-container.file-upload-section h6 {
  margin-top: 1rem;
}