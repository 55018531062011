.sidebar {
    background-color: #FFFFFF;
    width: 20vw;
    position: fixed;
    top: -15px;
    left: 0;
    height: 100vh;
    padding: 80px 20px;
    z-index: 100;
    overflow-y: auto;
    box-shadow: 0 0 2px #CCCCCC;
    transition: all 0.5s ease;
}
.sidebar::-webkit-scrollbar {
    display: none;
}
.menu-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.acc-header > .accordion-button {
    font-size: 14px;
    background-color: #E8EBF9;
    width: 20vw;
    padding: 10px 15px;
}
.acc-header > .accordion-button.collapsed {
    font-size: 14px;
    background-color: #F8F9FF;
    width: 20vw;
    padding: 10px 15px;
}
.menu-items {
    padding: 0;
    list-style: none;
}

.menu-icon {
    display: none;
  }

.sidebar .sidebar-inactive {
    left: -100%;
}
  

@media screen and (max-width: 800px) {
    .sidebar {
        left: -100%;
    }
}
.sidebar .bot-icon {
    width: 20vw;
    height: 60px;
    background-color: #F8F8F9;
    margin: 0;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #494470;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.sidebar .bot-icon span {
    font-size: 14px;
    color: #494470;
    margin-left: 10px;
}
.sidebar .bot-icon:hover {
    background-color: #8891cc;
}
.sidebar .bot-icon.selected {
    background-color: #5368E8;
}
.sidebar .bot-icon.selected  span {
    font-size: 14px;
    color: #FFFFFF;
}
.sideMenu-link {
    text-decoration: none;
}

.sidebar-nav-elements {
    display: none;
}

@media (max-width: 850px) {
    .menu-icon {
      display: block;
      cursor: pointer;
    }
  
    .sidebar-nav-elements {
      display: flex;
      position: absolute;
      z-index: 100;
      left: 0;
      top: 60px;
      background-color: #FFFFFF;
      border: 1px solid #d3caca;
      width: 20%;
      height: calc(100vh - 60px);
      transition: all 0.3s ease-in;
      overflow-y: auto;
      box-shadow: 0 0 2px #CCCCCC;
    }

    .sidebar-nav-elements .sidebar {
        width: 270px;
        position: fixed;
        left: 0;
        top: 60px;
        height: calc(100vh - 60px);
        z-index: 100;
        overflow-y: auto;
        box-shadow: 0 0 2px #CCCCCC;
        transition: all 0.5s ease;
    }
  
    .sidebar-nav-elements.active {
      width: 270px;
    }
  
    .sidebar-nav-elements .sidebar::-webkit-scrollbar {
        display: none;
    }

    .sidebar-nav-elements .menu-content {
        position: absolute;
        display: flex;
        width: 270px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        top: 0;
        left: 0;
    }
    
    .sidebar-nav-elements .accordion .acc-header > .accordion-button {
        font-size: 14px;
        background-color: #E8EBF9;
        width: 270px;
        padding: 10px 15px;
    }
    
    .sidebar-nav-elements .accordion .acc-header > .accordion-button.collapsed {
        font-size: 14px;
        background-color: #F8F9FF;
        width: 270px;
        padding: 10px 15px;
    }

    .sidebar-nav-elements .sidebar .bot-icon {
        width: 270px;
        height: 60px;
        background-color: #F8F8F9;
        margin: 0;
        padding-left: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.3s;
        color: #494470;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }

    .sidebar-nav-elements .sidebar .bot-icon span {
        font-size: 14px;
        color: #494470;
        margin-left: 10px;
    }
    .sidebar-nav-elements .sidebar .bot-icon:hover {
        background-color: #8891cc;
    }
    .sidebar-nav-elements .sidebar .bot-icon.selected {
        background-color: #5368E8;
    }
    .sidebar-nav-elements .sidebar .bot-icon.selected  span {
        font-size: 14px;
        color: #FFFFFF;
    }
    .sidebar-nav-elements .sideMenu-link {
        text-decoration: none;
        width: 270px;
    }

  }
