.app-container {
    display: flex;
    overflow: auto;
    max-height: 100vh;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0);
  }
  
  ::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(0, 0, 0, 0);
  }
