.test-case-wrapper {
    display: flex;
    flex-direction: column;
    margin: auto;
    position: absolute;
    left: 20vw;
    width: 80vw;
    padding: 1.5rem;
    height: 100vh;
    background-color: #FFFFFF;
    @media only screen and (max-width: 786px) {
        width: 100vw;
        left: 0;
    }
}
.select-input {
    color: #637883;
    font-size: 14px;
    font-weight: 400;
}
.test-case-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 15px;
}
.generate-test-btn {
    background-color: #D8DADA;
    border: 1px solid #D8DADA;
    border-radius: 5px;
    color: #323435;
    font-weight: 400;
}
.radio-label {
    color: #717577;
    font-size: 14px;
    font-weight: 500;
}
.radio-label > .form-check-input:checked {
    background-color: #717577;
    border-color: #717577;
}

.radio-label > .form-check-input:not([checked]) {
    border: 1px solid #717577;
}

.prompt-textarea {
    max-height: 50vh;
    overflow: auto;
}

.prompt-textarea:not([disabled]) {
    border: 1px solid #717577;
}

.test-output-section {
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    padding: 10px;
    background-color: cornsilk;
    border: 1px solid #ccc;
    overflow: auto;
    @media only screen and (min-width: 1600px) {
        height: 200px;
    }
}


