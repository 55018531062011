

.corpus-wrapper {
    background-color: #fff;
    width: 80vw;
    margin: auto;
    position: absolute;
    left: 20vw;
    padding: 1.5rem;
    height: 100vh;
    @media only screen and (max-width: 786px) {
      width: 100vw;
      left: 0;
    }
  }
  .corpus-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: space-between;
      align-items: center;
      height: fit-content;
      max-width: fit-content;
    }
    .chat-btn {
      margin-left: 100px;
      background-color: #1B154C;
      color: #fff;
      padding: 10px 20px;
      border-radius: 10px;
      font-size: 12px;
      max-width: fit-content;
      cursor: pointer;
    }
    .chat-btn:disabled {
      margin-left: 100px;
      background-color: #ccc;
      color: #000;
      padding: 10px 20px;
      border-radius: 10px;
      font-size: 12px;
      max-width: fit-content;
      cursor: pointer;
    }
    .chat-icon {
      cursor: pointer;
    }
    .search-corpus-field {
      width: 325px;
      height: 40px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;;
      background: url(../assets//icons/search_icon.svg) no-repeat 10px 10px;
      padding-left: 40px;
      border: 1px solid #D4D8EA;
    }
    
   .loading-icon {
      position: absolute;
      left: 50%;
      top: 30%;
    }

    .table-container-wrapper {
      width: 77vw;
      @media only screen and (max-width: 786px) {
        width: 90vw;
      }
    }