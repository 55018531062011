
.top-navbar-container {
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    width: 100vw;
    justify-content: space-between;
    align-items:center;
    height: 100%;
    -moz-box-shadow:0 3px 3px rgba(182, 182, 182, 0.75);
    -webkit-box-shadow: 0 3px 3px rgba(182, 182, 182, 0.75);
    box-shadow: 0 3px 3px rgba(182, 182, 182, 0.75);
  } 
  
  .top-navbar {
    height: 60px;
    width: 100%;
    max-width: 100%;
    background-color: #FFFFFF;
    position: relative;
    z-index: 1000;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-name {
    color: #1B154C;
    font-size: 20px;
    font-weight: 700;
  }

 .slash {
    color: #1B154C;
    font-size: 35px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .genai-text {
    color: #ffa500;
  }

  .evoke-genai {
    color: #1B154C;
    font-size: 14px;
    font-weight: 500;
  }

  .logo-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 10px 0 15px 10px;
  }

  .navbar-link {
    text-decoration: none;
    color: #1B154C;
  }

  .navbar-link:hover {
    text-decoration: underline;
  }

  .user-badge {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: lightgrey;
    border: calc(40px / 10) solid lightgray;
    /* White initials */
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: calc(40px / 1.75);
    font-weight: bold;
    /* And centered */
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .user-badge-avatar {
    /* Hide initials */
    color: transparent;
    /* Show image */
    background-image: url('../assets/icons/user-icon.svg');
    background-position: center;
    background-size: cover;
  }

  .username-txt {
    color: #434345;
    font-size: 14px;
    padding: 0 10px;
    font-weight: 600;
  }

  .user-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .notification-icon {
    position: relative;
    right: 5vw;
    cursor: pointer;
  }

  .logout-icon {
    cursor: pointer;
  }

  .user-txt {
    display: flex;
    flex-direction: column;
  }

  .email-txt {
    color: #434345;
    font-size: 11px;
    padding: 0 10px;
    font-weight: 400;
  }

  .logout-link {
    cursor: pointer;
  }
