.iframe-wrapper {
  background-color: #fff;
  width: 80vw;
  margin: auto;
  position: absolute;
  left: 20vw;
  padding: 1.5rem;
  height: 100vh;
  border: 1px solid #EFF0F6;
  border-radius: 10px;
  box-shadow: 2px 5px 5px #F6F5FB;
  overflow-y: auto;
  @media only screen and (max-width: 786px) {
    width: 100vw;
    left: 0;
  }
}
.iframe-container {
    width: 76vw;
    height: 75vh;
    position: absolute;
  @media only screen and (max-width: 850px) {
        width: 100vw;
        left: 0;
        padding: 1rem;
    }
  @media only screen and (min-width: 1280px) {
    height: 70vh;
  }
  @media only screen and (min-width: 1920px) {
    height: 75vh;
  }
}

.loading-iframe {
  display: flex;
  justify-content: center;
}
