  
  .config-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  ul.config-list {
    display: flex;
    flex-direction: row;
  }
  
  li.config-list-item {
    list-style: none;
    padding: 0 10px;
    font-weight: 600;
  }
  
  li.config-list-item:nth-child(2) > .config-list-item-link {
    text-decoration: none;
    color: #000;
    cursor: pointer;
  }
  
  li.config-list-item:first-child > .config-list-item-link {
    color: #ccc;
    text-decoration: none;
  }
  
  .config-list-item:last-child > .config-list-item-link {
    color: #ccc;
    text-decoration: none;
  }

  .menu-config-icon {
    display: none;
  }

  .bot-info {
    display: inline-flex;
    gap: 5px;
  }

  @media (max-width: 600px) {
    .menu-config-icon {
      display: block;
      cursor: pointer;
    }
  
    .nav-elements {
      position: absolute;
      right: 0;
      z-index: 1;
      top: 60px;
      background-color: #FFFFFF;
      border: 1px solid #d3caca;
      width: 0px;
      height: calc(100vh - 60px);
      transition: all 0.3s ease-in;
      overflow: hidden;
    }
  
    .nav-elements.active {
      width: 270px;
    }
  
    .nav-elements ul.config-list  {
      display: flex;
      flex-direction: column;
    }
  
    .nav-elements ul li {
      margin-right: unset;
      margin-top: 22px;
    }
  }

  .bot-additional-func {
    cursor: pointer;
  }

  