.prompt-container-wrapper {
    background: #FFFFFF;
    position: absolute;
    left: 20vw;
    height: 100vh;
    padding: 1.5rem;
    width: 80vw;
    @media only screen and (max-width: 786px) {
        width: 100vw;
        left: 0;
    }
}

.submit-prompt {
    float: right;
    background-color: #FFF;
    color: #637883;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    border: 1px solid #D4D8EA;
    margin-bottom: 10px;
    cursor: pointer;
}

.plus-icon {
  color: #637883;
  font-weight: 400;
  margin:  0 10px 3px 0;
}

.submit-prompt:hover {
  background: #E8EBF9;
  color: #637883;
  border: 1px solid #D4D8EA;
}

.submit-prompt:hover .plus-icon {
  font-weight: 400;
  margin-right: 10px;
}

.search-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 786px) {
    flex-direction: column;
    gap: 10px;
  }
}

  .prompt-btn {
    cursor: pointer;
  }
  
  .textarea-section {
    margin: 5px;
    padding: 5px 10px;
    width: 600px;
    resize: both;
    max-height: 50vh;
    overflow: auto;
    max-width: 600px;
  }

  .textarea-section:focus {
    margin: 5px;
    padding: 5px 10px;
    background-color: #FFF;
    width: 600px;
    resize: both;
  }

  .all-icons {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .search-field {
    width: 325px;
    height: 40px;
    font-size: 14px;
    background: url(../assets//icons/search_icon.svg) no-repeat 10px 10px;
    padding-left: 40px;
    border: 1px solid #D4D8EA;
    @media only screen and (max-width: 786px) {
      width: 100%;
    }
  }

  .loading-icon {
    position: absolute;
    left: 50%;
    top: 30%;
  }

  