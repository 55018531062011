
.chat-container {
    height: auto;
    background: #fff;
}

.horizontal-line {
    margin: 0 !important;
}

.chat-response-frame {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    padding: 0;
    @media screen and (min-width: 1900px) {
      height: 75vh;
    }
}

.chat-input-frame .card {
    width: 100%; 
    border: none;
    /* border-top: none;
    border-radius: 0 0  0.375rem 0.375rem; */
    top: -8px;
}

.chat-response-frame .card {
    width: 100%; 
    /* overflow-y: auto; */
    border: none;
    /* border-bottom: none;
    border-radius: 0.375rem 0.375rem 0 0; */
}

.chat-response-frame .card .card-body .scrollable-response {
  height: 48.1vh;
  overflow-y: auto;
  @media screen and (min-width: 1900px) {
    height: 65vh;
  }
}

.chat-message {
    margin-bottom: 10px;
}

.chat-message.human {
    text-align: right;   
}

.chat-message.ai {
    text-align: left;
}

.chat-message .user-icons .user-section, .chat-message .user-icons .ai-section {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.chat-message .user-icons .user-msg {
  background-color: #F5F5F6;
  padding: 12px;
  gap: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #1D1D1F;
  font-weight: 600;
  border: 1px solid #F5F5F6;
  border-radius: 5px 0px 5px 5px;
  max-width: 95%;
}

.chat-message .user-icons .ai-msg {
  background-color: #E8EBF9;
  padding: 12px;
  font-size: 12px;
  line-height: 16px;
  color: #1D1D1F;
  font-weight: 600;
  gap: 10px;
  border: 1px solid #E8EBF9;
  border-radius: 0px 5px 5px 5px;
  max-width: 95%;
}

.chat-message .user-icons .ai-msg:before {
  content: '';
  position: relative;
  width: 0;
  height: 0;
  left: -17px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 5px solid;
  border-color: #E8EBF9 transparent transparent transparent;
}

.chat-message .user-icons .user-msg:after{
  content: '';
  position: relative;
  width: 0;
  height: 0;
  right: -17px;
  left: auto;
  top: -8px;
  bottom: auto;
  border: 5px solid;
	border-color: #F5F5F6 transparent transparent transparent;
}

  .chat-input-frame {
    position: relative;
    top: 0;
    padding: 0;
  }

  .chat-messenger {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .text-input-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
  }
  
  .send-btn {
    position: absolute; 
    right: 14px;
    border: none; 
    background-color: #5368E8; 
    cursor: pointer; 
  }

  .send-btn:focus {
    border: none;
  }

  .text-input-field {
    background-color: #fff;
    font-size: 14px;
    width: 94%;
  }

  .button-upload:focus {
    border: none;
  }

  .user-badge-icon {
    height: 36px;
    width: 36px;
    border-radius: 100px;
    background-color: lightgrey;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: calc(40px / 1.75);
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }

  .user-badge-avatar-icon {
    color: transparent;
    background-image: url('../assets/icons/user-img.svg');
    background-position: center;
    background-size: cover;
  }

  .ai-badge-icon {
    height: 36px;
    width: 36px;
    border-radius: 100px;
    background-color: lightgrey;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: calc(40px / 1.75);
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  .ai-badge-avatar-icon {
    /* Hide initials */
    color: transparent;
    /* Show image */
    background-image: url('../assets/icons/bot-img.svg');
    background-position: center;
    background-size: cover;
  }

.screencast-btn {
  border: 1px solid #ccc;
  background-color: #fff;
  color: #000;
}

.special-char {
  background-color: #ece8e8;
  color: #1b8429;
  padding: 3px;
}

.settings-checkbox-wrapper {
  margin-top: 20px;
}

.settings-checkbox-info-text {
  font-size: 13px;
  color: #b3b0b0;
  margin-bottom: 1.5rem;
}

[data-bs-theme=dark] .modal-content {
  color: #fff;
}

.regenerate-btn-wrapper {
  position: relative;
  width: 141px;
  margin: 0 auto;
}

.regenerate-btn {
  background-color: #fff;
  color: #1D1D1F;
  border: 1px solid #1D1D1F;
  border-radius: 100px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
  box-shadow: 8px 0 8px 0 #000000;
}

.regenerate-btn img {
  margin-right: 8px;
}


.regenerate-btn:hover {
  background-color: #ccc;
  border: 1px solid #fff;
  box-shadow: 8px 0 8px 0 #cccccc;
}

.chat-message-container .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loader {
  position: absolute;
  left: 50%;
}

