.chat {
    z-index: 100000;
    position: relative;
    display: flex;
    flex-direction: row;
    @media only screen and (min-width: 820px) {
      left: 170px;
    }
    @media only screen and (min-width: 1024px) {
      left: 195px;
    }
}

.chat-prompt {
    position: absolute;
    top: 65px;
    display: flex;
    flex-direction: row;
    @media only screen and (min-width: 820px) {
      left: 170px;
    }
    @media only screen and (min-width: 1024px) {
      left: 19vw;
    }
}

.chat-config {
  position: absolute;
  top: 45px;
  display: flex;
  flex-direction: row;
  @media only screen and (min-width: 820px) {
    left: 170px;
  }
  @media only screen and (min-width: 1024px) {
    left: 17vw;
  }
}

.chat-door-config {
  position: absolute;
  top: 45px;
  display: flex;
  flex-direction: row;
}

.chat-prompt-unauthorized, .chat-config-unauthorized, .chat-door-config-unauthorized {
  position: relative;
  left: 5vw;
  top: 5vh;
}

.chat > .chat-wrapper > header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  color: black;
  padding: 5px 0 5px 50px;
  font-weight: 700;
}

.chat-prompt > .chat-wrapper > header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  color: black;
  padding: 5px;
  font-weight: 700;
}

.chat > .chat-wrapper > header > .filler, .chat-prompt > .chat-wrapper > header > .filler  {
  flex: 0 10000 100%;
}

.chat > .chat-wrapper > header > span, .chat-prompt > .chat-wrapper > header > span {
  padding: 0 0 0 12px;
  font-size: 20px;
  position: relative;
  bottom: 2px;
}

 .back-arrow {
  cursor: pointer;
}

.prev-session {
  background-color: #D3D3D3;
  width: 15vw;
  height: 90vh;
  position: relative;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  @media only screen and (min-width: 1280px) {
    height: 85vh;
  }
}

.circle {
  color: #000;
  padding: 10px 5px 0 0;
  cursor:pointer
}

.prev-session-container {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-content: baseline;
  align-items: baseline;
}

.prev-session-header {
  font-size: 13px;
  margin: auto;
  padding: 10px 0 0 10px;
  font-weight: 700;
}

.session-container {
  display: flex;
  flex-direction: column;
}

.month {
  font-size: 12px;
  font-weight: 500;
  margin: auto;
}

.session-list .session-list-item {
  list-style: none;
  font-size: 12px;
  padding: 2px;
  cursor: pointer;
}

.chat-header {
  background-color: #fff;
  @media only screen and (min-width: 820px) {
    left: 170px;
  }
  @media only screen and (min-width: 1024px) {
    left: 195px;
  }
}

