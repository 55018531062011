.llm-chat-container {
    position: absolute;
    height: calc(100vh - 60px - 5vh);
    background: #fff;
    overflow: hidden;
    width: calc(100vw - 20vw);
    left: 20vw;
    padding: 0 5px;
}
 .llm-chat-container .chat-messenger {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

 .llm-chat-container .radio-input-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
  }

  .llm-chat-container .radio-input-label {
    margin-bottom: 10px;
  }

  .llm-chat-container .heading {
    text-align: center;
    background-color: #1B154C;
    color: #fff;
    height: fit-content;
    border: 0px 0px 1px 0px;
    padding: 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }

  .llm-chat-container .sub-header {
    padding: 20px;
    gap: 12px;
    margin: 0 100px;
    box-sizing: border-box;
    border: 1px solid #C6C6C6;
    border-radius: 8px;
    background-color: #F8F8F9;
  }

  .llm-chat-container .sub-header-text {
    color: #1D1D1F;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
  }

.llm-chat-container .horizontal-line {
  margin: 0 !important;
}

.llm-chat-container .chat-response-frame {
  margin: 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 310px - 5vh);
  padding: 0;
}

.llm-chat-container .chat-input-frame .card {
  width: 100%; 
  border: none;
  top: -8px;
}

.llm-chat-container .chat-response-frame .card {
  width: 100%; 
  overflow-y: auto;
  border: none;
}

.llm-chat-container .chat-message {
  margin-bottom: 10px;
}

.llm-chat-container .chat-message.user {
  text-align: right;   
}

.llm-chat-container .chat-message.ai {
  text-align: left;
}

.llm-chat-container .chat-message .user-icons .user-section, .chat-message .user-icons .ai-section {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.llm-chat-container .chat-message .user-icons .user-msg {
  background-color: #F5F5F6;
  padding: 12px;
  gap: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #1D1D1F;
  font-weight: 600;
  border: 1px solid #F5F5F6;
  border-radius: 5px 0px 5px 5px;
}

.llm-chat-container .chat-message .user-icons .ai-msg {
  background-color: #E8EBF9;
  padding: 12px;
  font-size: 12px;
  line-height: 16px;
  color: #1D1D1F;
  font-weight: 600;
  gap: 10px;
  border: 1px solid #E8EBF9;
  border-radius: 0px 5px 5px 5px;
}

.llm-chat-container .chat-message .user-icons .ai-msg:before {
  content: ' ';
  position: relative;
  width: 0;
  height: 0;
  left: -17px;
  right: auto;
  top: -9px;
  bottom: auto;
  border: 5px solid;
  border-color: #E8EBF9 transparent transparent transparent;
}

.llm-chat-container .chat-message .user-icons .user-msg:after{
  content: ' ';
  position: relative;
  width: 0;
  height: 0;
  right: -17px;
  left: auto;
  top: -9px;
  bottom: auto;
  border: 5px solid;
  border-color: #F5F5F6 transparent transparent transparent;
}

.llm-chat-container > .chat-input-frame {
  position: relative;
  top: 0;
  padding: 0;
  margin: 0 80px;
}

.radio-label-llm, .radio-label-vector {
  background-color: #d2d4d6;
  border-radius: 50px;
  padding: 4px 12px 4px 5px;
  gap: 6px;
}

.form-check {
 padding-left: 2.5em !important;
}

.radio-label-llm-checked, .radio-label-vector-checked {
  background-color: #0095C8;
  color: #fff;
}

.form-check-input[type=radio] {
  border: 1px solid #637381 !important;
  cursor: pointer;
}

.form-check-input:checked[type=radio] {
  border: 2px solid #fff !important;
  background-color: #0095C8 !important;
}
