.search-tool-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 10px;
}

.search-tools-field {
    width: 325px;
    height: 40px;
    font-size: 14px;
    background: url(../assets//icons/search_icon.svg) no-repeat 10px 10px;
    padding-left: 40px;
    border: 1px solid #D4D8EA;
    border-radius: 30px;
    background-color: #F4F3F9;
    @media screen and (max-width: 850px) {
        width: 100%;   
    }

  }

.dashboard-link {
    text-decoration: none;
}

.dashboard-wrapper {
    position: absolute;
    top: 60px;
    left: 12.5vw;
    width: 75vw;
    background-color: #FFFFFF;
    padding: 1.5rem;
    height: calc(100vh - 63px - 5vh);
    overflow-y: auto;
}

.dashboard-container {
    position: relative;
}

.dashboard-header {
    font-size: 20px;
    font-weight: 500;
    color: #717577
}

.homeacc-header > .accordion-button {
    background-color: #ECECEC;
    font-size: 18px;
    font-weight: 500;
}

.homeacc-header > .accordion-button:not(.collapsed) {
    background-color: #D8F2F3;
}

.homeacc-body {
    padding: 10px;
    height:fit-content;
}

ul.tool-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
    align-content: center;
    @media screen and (max-width: 850px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.tool-item {
    list-style: none;
    background-color: #F8F8F9;
    padding: 10px 20px;
    border: 1px solid #F8F8F9;
    border-radius: 5px;
    width: 200px;
}

.tool-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
}

.tool-text {
    color: #494470;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}
