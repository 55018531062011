.results-wrapper {
    display: flex;
    flex-direction: column;
    height: 50%;
    overflow-y: auto;
    border-bottom: 2px solid #E5E6E6;
}

.results-header {
    padding: 5px 0 0 20px;
}

ul.product-lists, ul.design-lists, ul.construction-lists {
    list-style-type: none;
}

ul.product-lists {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: -10px ;
    margin-right: 10px;
    gap: 10px;
    @media only screen and (min-width: 1400px) {
        flex-direction: row;
    }
}

li.product-item {
    box-sizing: border-box;
    border: 1px solid #E5E6E6;
    font-size: 12px;
    @media only screen and (min-width: 1400px) {
        width: 28vw;
    }
}

.product-ratings {
    padding: 10px;
    display: flex;
    flex-direction: row;
}

img.config-image {
    object-fit: cover;
    height: 70px;
    width:70px;
}

.product-ratings > .product-ratings-1 {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.product-ratings > .product-ratings-2 {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.rating-star {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    margin-left: 10px;
}

.rating {
    display: flex;
    flex-direction: row;
}

.design-image {
    display: flex;
    flex-direction: column;
}

ul.design-lists {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 15px;
}

.design-name {
    font-size: 12px;
}

ul.construction-lists {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: -10px;
}

li.construction-item {
    box-sizing: border-box;
    border: 1.5px solid #E5E6E6;
    display: flex;
    flex-direction: row;
    width: 48%;
}

.construction-data {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.design-image-item {
    object-fit: cover;
    height: 100px;
    width: 100px;
}

.construction-image {
    margin: 10px
}

.construction-image-item {
    object-fit: cover;
    width: 70px;
    height: 70px;
}

.construction-name {
    font-size: 12px;
    display: flex;
    font-weight: 500;
}

.construction-type {
    font-size: 10px;
    color: #ab9e9e;
}

.construction-price {
    font-size: 10px;
}

.construction-price-text {
    color: #ab9e9e;
}

.construction-amt {
    font-weight: 500;
}

.preview-wrapper {
    display: flex;
    flex-direction: column;
    height: 50%;
    overflow-y: auto;
}

.preview-header {
    padding: 10px 0 0 20px;
}

.preview-img {
    position: relative;
    width: 40vw; 
    height: 40vh;
    margin: auto;
}

img.preview-image-item {
    width: 100%;
    height: 100%;
}

.door-image {
    position: absolute;
    object-fit: cover; 
}

.series-heading{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    background-color: #E5E6E6;
    height: fit-content;
    padding: 5px;
    font-weight: 500;
    word-wrap: break-word;
}

.copy-icon {
    cursor: pointer;
}

.loading-result-icon {
    margin: auto;
}