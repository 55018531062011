header h5 .cpq-header{
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.chat-cpq-response-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px - 115px - 5vh);
  padding: 0;
  overflow-y: auto;
}

.chat-cpq-message .user-icons .user-section, .chat-cpq-message .user-icons .ai-section {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  
.chat-cpq-message .user-icons .user-msg {
  background-color: #F5F5F6;
  padding: 12px;
  gap: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #1D1D1F;
  font-weight: 600;
  border: 1px solid #F5F5F6;
  border-radius: 5px 0px 5px 5px;
  }
  
.chat-cpq-message .user-icons .ai-msg {
  background-color: #D8F6FF;
  padding: 12px;
  font-size: 12px;
  line-height: 16px;
  color: #1D1D1F;
  font-weight: 600;
  gap: 10px;
  border: 1px solid #D8F6FF;
  border-radius: 0px 5px 5px 5px;
  }
  
.chat-cpq-message .user-icons .ai-msg:before {
  content: ' ';
  position: relative;
  width: 0;
  height: 0;
  left: -17px;
  right: auto;
  top: -9.5px;
  bottom: auto;
  border: 5px solid;
  border-color: #D8F6FF transparent transparent transparent;
  }
  
.chat-cpq-message .user-icons .user-msg:after{
  content: ' ';
  position: relative;
  width: 0;
  height: 0;
  right: -17px;
  left: auto;
  top: -9.5px;
  bottom: auto;
  border: 5px solid;
  border-color: #F5F5F6 transparent transparent transparent;
  }

 .chat-cpq-message .user-badge-icon {
    height: 36px;
    width: 36px;
    border-radius: 100px;
    background-color: lightgrey;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: calc(40px / 1.75);
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
  
  .chat-cpq-message .user-badge-avatar-icon {
    color: transparent;
    background-image: url('../assets/icons/user-img.svg');
    background-position: center;
    background-size: cover;
  }
  
 .chat-cpq-message  .ai-badge-icon {
    height: 36px;
    width: 36px;
    border-radius: 100px;
    background-color: lightgrey;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: calc(40px / 1.75);
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }
  
  .chat-cpq-message .ai-badge-avatar-icon {
    color: transparent;
    background-image: url('../assets/icons/cpq-bot.svg');
    background-position: center;
    background-size: cover;
  }

.chat-cpq-message {
    margin-bottom: 10px;
}

.chat-cpq-message.user {
    text-align: right;   
}

.chat-cpq-message.ai {
    text-align: left;
}

.send-cpq-btn {
  position: absolute; 
  right: -2px;
  border: none; 
  cursor: pointer; 
}

.send-cpq-btn:focus {
  border: none;
}