.footer-container {
    display: flex;
    justify-content: space-between;
    align-items:center;
    color: #fff;
    font-size: 12px;
    padding: 5px;
}

footer {
    height: 5vh;
    background-color: #1B154C;
    position:fixed;
    bottom:0;
    left:0;
    width: 100%;
    max-width: 100%;
    z-index: 1000;
    @media only screen and (max-width: 786px) {
        height: 50px;
    }
}

.footer-text, .website-text {
    padding: 0 10px;
}