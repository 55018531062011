
  
  .connect-spinner {
    display: flex;
    margin: auto;
    text-align: center;
  }
  
  .connect-spinner > .content {
    margin: auto;
  }
  
  .connect-spinner > .content > .icon {
    font-size: 64px;
  }

  .web-chat-window > .chat-box {
    background-color: #DDE5ED;
    /* backdrop-filter: blur(10px); */
    backdrop-filter: none;
    border: 4px solid #d3d3d3;
    box-shadow: 0 0 20px rgba(125, 117, 117, 0.2);
    display: flex;
    flex-direction: column;
    width: 50vw;
    min-width: 40vw;
    position: fixed;
    height: 80vh;
    top: 8%;
    width: 30%;
    @media only screen and (max-width: 1024px) {
      width: 80vw; 
    }
  }

  .web-chat-prompt-window > .chat-box {
    background-color: #DDE5ED;
    backdrop-filter: blur(10px);
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 0 20px rgba(210, 204, 204, 0.2);
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 75vw;
    left: 15px;
    margin: 15px 25px;
    @media only screen and (min-width: 300px) and (max-width: 819px) and (orientation: portrait) {
      left: 0;
      margin: 15px;
      height: 76vh;
      width: 90vw;
    }
    @media only screen and (min-width: 600px) and (max-width: 1024px) and (orientation: portrait) {
      height: 81vh;
      width: 97vw;
    }
    @media only screen and (min-width: 820px) and (max-width: 1180px) and (orientation: portrait) {
      left: 0;
      margin: 15px;
      height: 85vh;
      width: 78vw;
    }
    @media only screen and (min-width: 600px) and (max-width: 1024px) and (orientation: landscape) {
      left: 0;
      margin: 15px 25px;
      height: 75vh;
      width: 75vw;
    }
  }


  .web-chat-config-window > .chat-box {
    background-color: #fff;
    backdrop-filter: blur(10px);
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 0 20px rgba(210, 204, 204, 0.2);
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    left: 0;
    margin-top: 15px;
    @media only screen and (min-width: 300px) and (max-width: 819px) and (orientation: portrait) {
      left: 0;
      width: 100vw;
    }
    @media only screen and (min-width: 600px) and (max-width: 1024px) and (orientation: portrait) {
      width: 100vw;
    }
    @media only screen and (min-width: 820px) and (max-width: 1180px) and (orientation: portrait) {
      left: 0;
      width: 100vw;
    }
    @media only screen and (min-width: 600px) and (max-width: 1024px) and (orientation: landscape) {
      left: 0;
      width: 100vw;
    }
  }
  
  .web-chat-window > button.maximize {
    background-color:#D3D3D3;
    border-radius: 50%;
    border: 2px solid D3D3D3;
    bottom: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    color: White;
    font-size: 30px;
    height: 64px;
    outline: 0;
    position: fixed;
    right: 20px;
    width: 64px;
  }

    
  .web-chat-window > button.maximize > .red-dot {
    color: #EF0000;
    font-size: 14px;
    position: absolute;
    right: 12px;
    top: 12px;
  }
  
  .web-chat-window  > button.maximize:focus:not(:active),
  .web-chat-window  > button.maximize:hover:not(:active) {
    background-color: #D3D3D3;
    border-color: #D3D3D3;
  }
  
  .web-chat-window  > button.maximize:active {
    background-color: White;
    color: #D3D3D3;
  }
  
  .web-chat-window > .chat-box.hide {
    display: none;
  }
  
  .web-chat-window > .chat-box.left {
    left: 20px;
  }
  
  .web-chat-window > .chat-box.right, .web-chat-prompt-window > .chat-box.right {
    right: 20px;
  }
  
  .web-chat-window > .chat-box > header {
    background-color: #fff;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom:  1px solid #E5E6E6;
  }

  .web-chat-prompt-window > .chat-box > header, .web-chat-config-window > .chat-box > header {
    background-color: #fff;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom:  1px solid #E5E6E6;
  }

  .web-chat-window > .chat-box > header > span,  .web-chat-prompt-window > .chat-box > header > span,
  .web-chat-config-window > .chat-box > header > span {
    color: rgb(96, 91, 91);
    position: relative;
    margin: auto 10px;
    font-weight: 600;
    font-size: 15px;
  }
  
  .web-chat-window > .chat-box > header > .btn-wrapper > button {
    width: 40px;
    height: 40px;
    background-color: Transparent;
    border: 0;
    color: rgb(96, 91, 91);
    outline: 0;
  }

  .web-chat-window > .chat-box > header > .btn-wrapper {
    display: flex;
  }
  
  .web-chat-window  > .chat-box > header > .btn-wrapper  > button:focus:not(:active),
  .web-chat-window  > .chat-box > header >  .btn-wrapper > button:hover:not(:active) {
    color: rgba(143, 137, 137, 0.6);
  }
  
  .web-chat-window  > .chat-box > header >  .btn-wrapper > button:focus:active {
    color: rgba(140, 133, 133, 0.4);
  }

 .react-web-chat {
   display: flex;
  }

  .web-chat-items {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .doorconfig-web-chat {
    display: flex;
    width: 40% !important;
    border-right: 2px solid #E5E6E6;
  }

  .chat-container-section {
    display: flex;
    flex-direction: row;
    background-color: #fff !important;
    width: 100% !important;
    height: 83vh;
    @media screen and (min-width: 375px) {
      height: 83vh;
    }
    @media screen and (min-width: 768px) {
      height: 83vh;
    }
    @media screen and (min-width: 992px) {
      height: 83vh;
    }
    @media screen and (min-width: 1024px) {
      height: 76vh;
    }
    @media screen and (min-width: 1280px) {
      height: 76vh;
    }
    @media screen and (min-width: 1920px) {
      height: 85vh;
    }
  }

  .bot-message-service {
    width: 60% !important;
    display: flex;
    flex-direction: column;
  }

 .other-chat-section {
    flex: 1;
    height: 76vh;
    @media screen and (min-width: 375px) {
      height: 76vh;
    }
    @media screen and (min-width: 768px) {
      height: 76vh;
    }
    @media screen and (min-width: 992px) {
      height: 76vh;
    }
    @media screen and (min-width: 1024px) {
      height: 70vh;
    }
    @media screen and (min-width: 1280px) {
      height: 70vh;
    }
    @media screen and (min-width: 1920px) {
      height: 78vh;
    }
  }

 .smart-doc-chat-section  {
    flex: 1;
    height: 78vh;
    @media screen and (min-width: 375px) {
      height: 78vh;
    }
    @media screen and (min-width: 768px) {
      height: 78vh;
    }
    @media screen and (min-width: 992px) {
      height: 78vh;
    }
    @media screen and (min-width: 1024px) {
      height: 72vh;
    }
    @media screen and (min-width: 1280px) {
      height: 72vh;
    }
    @media screen and (min-width: 1920px) {
      height: 80vh;
    }
  }

  .result-section {
    border-bottom: 5px solid #E5E6E6;
  }

  .refresh-btn {
    background-color: #fff;
    border: none;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
  }

  .refresh-btn:hover {
    background-color: transparent;
    border: none;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
  }


  



  

 